import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { actions } from "../actions";
import HomeMenu from './home-menu';

import _ from "lodash";
import AuthenticatedTopMenuBar from './authenticated-top-menu-bar';

const MainMenu = (props) => {

    const { authenticated, name, email } = props;

    const isAlphanumeric = (str) => {
        const regex = /^[a-z0-9]+$/i;
        return regex.test(str);
    }

    const profileLetter = name ? isAlphanumeric(name) ? name.charAt(0).toLowerCase() : email?.charAt(0).toLowerCase() : email?.charAt(0).toLowerCase();

    return (
		<>	
            {authenticated && <AuthenticatedTopMenuBar profileLetter={profileLetter} />}
            {!authenticated && <HomeMenu />}
		</>
	)
}

function mapReduxStateToProps( state ) {

	return {
		authenticated: state.auth.authenticated,
		name: state.account.full_name,
		email: state.account.email
	};

}

export default withRouter(connect(
	mapReduxStateToProps
)(MainMenu));