import React, { Component, createContext, useEffect } from 'react';
import Loadable from '@cargo/loadable-components'
import { matchPath, MemoryRouter, __RouterContext } from "react-router";
import { withRouter, Redirect} from 'react-router-dom';
import { connect } from 'react-redux';
import { actions } from "../actions";
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { isServer } from "@cargo/common/helpers";
import { store } from '../index';

/* Route components */
import Home from './home-page';
import InformationPageHandler from './information-page-handler';
import Templates from "./templates";
import Community from "./community";
import CommunityMenu from './community-menu';
import SitesList from "./siteslist";
import PublicFolder from './public-folder';
import SitePreviewer from "./site-previewer";
import FontsPage from './fonts-page';
import NewsletterProxy from './legacy-newsletter-proxy';
import { Login } from "@cargo/common/login";

export const staticPageComponents = {
	'students': Loadable({ loader: () => import('./static-pages/students')}),
	// 'shops': Loadable({ loader: () => import('./static-pages/shops')}),
	'terms': Loadable({ loader: () => import('./static-pages/terms')}),
	'privacy': Loadable({ loader: () => import('./static-pages/privacy')}),
	'data-processing-agreement': Loadable({ loader: () => import('./static-pages/data-processing')}),
	'copyright-issues': Loadable({ loader: () => import('./static-pages/copyright-issues')}),
	'information': Loadable({ loader: () => import('./static-pages/information')}),
}

export const paths = {
	ROOT: '/',
	LOGIN: '/login',
	//STATIC_PAGES: `/:page(${Object.keys(staticPageComponents).join('|')}|00[1-9]|0[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-4])/:param1?`,
	STATIC_PAGES: `/:page(${Object.keys(staticPageComponents).join('|')})/:param1?`,
	FOLDER: '/:folder',
	PUBLIC_FOLDER: '/folder/:userid/:folder',
	PUBLIC_FOLDER_PREVIEW: '/folder/:userid/:folderSlug/preview/:siteid',
	USEFUL_MUSIC: '/useful-music-:edition',
	COMMUNITY: '/community/:category',
	TEMPLATES: '/templates/:folder?',
	DESIGN_LAB: '/designlab/:folder?',
	FONTS: '/fonts',
	PREVIEW: '/:origin(templates)/preview/:siteid',
	PREVIEW_ROOT: '/:folder*/preview/:siteid',
	ACCOUNT: '/account/:path?/:param1?/:param2?',
	RATES: '/rates',
}

const previewOverlayJSX = ( match, isPublicFolder ) => {

	return( 
		<SitePreviewer 
			siteToPreview={match.params.siteid}
			fromPublicFolder={isPublicFolder}
		/>
	)
}

class AccountManagerRouteComponent extends Component {

	constructor(props) {
		super(props);

		this.AccountManagerWindow = Loadable({ loader: () => import('./account-manager-window')});
	}

	render() {
		return null;
	}

	componentDidMount() {

		if(!this.props.authenticated) {
			return false
		}

		if(!this.props.activeUIWindows.hasOwnProperty('c3-account-manager-window')) {

			this.props.addUIWindow({
				group: 'overlay',
				component: () => <__RouterContext.Consumer>
					{routerState => {
						return <__RouterContext.Provider value={this.props.context}>
							<this.AccountManagerWindow></this.AccountManagerWindow>
						</__RouterContext.Provider>
					}}
				</__RouterContext.Consumer>,
				id: `c3-account-manager-window`,
				props: {
					type: 'popover', 
					positionType: 'center', 
					windowName: 'c3-account-manager',
					clickoutLayer: true,
					clickoutLayerDim: true,
					draggingUploadedImage: false,
					disableDragging: false,
					preventClickout: true
				}
			});

		}

	}

	componentWillUnmount() {

		// remove all UI windows
		this.props.removeUIWindow(uiWindow => {
			if( !uiWindow.props.clickoutLayer && !uiWindow.props.preventClickout ){
				return false;
			}
			return true;
		});

	}

}

const AccountManagerRoute = connect(
	(state, ownProps) => ({
		authenticated: state.auth.authenticated,
		activeUIWindows: state.uiWindows.byId
	}), 
	dispatch => bindActionCreators({
		addUIWindow: actions.addUIWindow,
		updateUIWindow: actions.updateUIWindow,
		removeUIWindow: actions.removeUIWindow
	}, dispatch)
)(AccountManagerRouteComponent)

export const routes = [
	{
		path: paths.USEFUL_MUSIC,
		requireLogin: false,
		redirectPath: match => {
			return `https://usefulmusic.cargo.site/Useful-Music-${match.params.edition}`
		},
		render: function(layer) {
			window.location.href = routesByPath[paths.USEFUL_MUSIC].redirectPath(layer.match);
			return null;
		}
	},
	{
		path: paths.PUBLIC_FOLDER_PREVIEW,
		requireLogin: false,
		isOverlay: true,
		basePath: (layer) => {
			return layer.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, '') || '/'
		},
		render: function(layer) {
			return previewOverlayJSX(layer.match, true)
		}
	},
	{
		path: paths.PREVIEW,
		requireLogin: false,
		isOverlay: true,
		basePath: (layer) => {
			return layer.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, '') || '/'
		},
		render: function(layer) {
			return previewOverlayJSX(layer.match, false)
		}
	},
	{
		path: paths.PREVIEW_ROOT,
		requireLogin: true,
		isOverlay: true,
		basePath: (layer) => {
			return layer.location.pathname.replace(/\/preview\/[\w\d]+\/?$/, '') || '/'
		},
		render: function(layer) {
			return previewOverlayJSX(layer.match, false)
		}
	},
	{
		path: paths.ACCOUNT,
		requireLogin: true,
		isOverlay: true,
		render: function(layer, context) {
			return <AccountManagerRoute context={context} />
		}
	},
	{
		path: paths.LOGIN,
		requireLogin: false,
		isOverlay: false,
		render: function(layer) {
			return <Login 
				getAuthTokenAction={actions.getAuthToken}
				canCreateNewAccount={false}
				onLoginSuccess={data => {
					this.props.history.replace(`/`);
				}}
			/>
		},
		renderStandalone: true
	},
	{
		path: paths.STATIC_PAGES,
		requireLogin: false,
		get isOverlay() {

			const state = store.getState();

			if(state.auth.authenticated) {
				// when in authed homepage we want to overlay
				return true;
			}

			return false;
		},
		render: function(layer) {

			if(layer.match.params.page) {

				const newsletterEditionAsInt = parseInt(layer.match.params.page);

				if(
					newsletterEditionAsInt
					&& newsletterEditionAsInt >= 1
					&& newsletterEditionAsInt <= 254
				) {
					if(this.props.authenticated) {
						return <SitesList />
					} else {
						return <NewsletterProxy edition={layer.match.params.page} />
					}
				}
			}

			return <InformationPageHandler />
		}
	},
	{
		path: paths.RATES,
		requireLogin: false,
		isOverlay: false,
		redirectPath: match => {
			return `/information`
		},
		render: function(layer) {
			return <Redirect to="/information" />;
		}
	},
	{
		path: paths.FONTS,
		requireLogin: false,
		isOverlay: false,
		render: function(layer) {
			return <FontsPage />
		}
	},
	{
		path: paths.TEMPLATES,
		requireLogin: false,
		isOverlay: false,
		render: function(layer) {
			return <Templates />
		}
	},
	{
		path: paths.DESIGN_LAB,
		requireLogin: false,
		isOverlay: false,
		redirectPath: match => {
			return `/templates`
		},
		render: function(layer) {
			return <Redirect to="/templates" />;
		}
	},
	{
		path: paths.COMMUNITY,
		isOverlay: false,
		render: function(layer) {

			const communityMatch = matchPath(this.props.location.pathname, { path: paths.COMMUNITY, exact: true });

			if( 
				this.props.isMobile 
				&& communityMatch 
				&& communityMatch.params.category !== 'all'
			){
				return <Redirect to="/community/all" />;
			}

			return (
				<>
					{ this.props.isMobile ? null:  <CommunityMenu history={this.props.history}/>}
					<Community isMobile={this.props.isMobile} />
				</>
			)
		}
	},
	{
		path: paths.PUBLIC_FOLDER,
		requireLogin: false,
		isOverlay: false,
		render: function(layer) {
			return <PublicFolder />
		}
	},
	{
		path: paths.FOLDER,
		requireLogin: true,
		isOverlay: false,
		render: function(layer) {
			return <SitesList />
		},
	},
	{
		path: paths.ROOT,
		requireLogin: false,
		isOverlay: false,
		render: function(layer) {
			if(this.props.authenticated) {
				// render user sites
				return <SitesList />
			} else {
				return (
					<div id="home-page">
						<Home />
						{this.props.isMobile && <Community isMobile={this.props.isMobile} />}
					</div>
				)
			}
		}
	},
	{
		path: '*',
		requireLogin: false,
		isOverlay: false,
		render: function(layer) {
			if(!this.lockedUnderlayLocation) {
				// no overlay nor underlay route has been matched.
				return <Redirect to="/" />;
			}
		}
	}
]

class Router extends Component {
	
	constructor(props) {

		super(props);
		
		const initialLocation = this.props.location;
		// router stack contains a { match, location, history } item for every layer
		this.state = {
			layerStack: this.getNextLayerStack([], this.props.location),
			renderMainRoute: () => {
				return this.renderLayers(0,1)
			},
			renderOverlays: () => {
				return this.renderLayers(1)
			},
			closeOverlay: () => {

				if(this.state.layerStack.length <= 1) {
					return;
				}

				const newStack = [...this.state.layerStack];

				newStack.pop();

				// update URL to reflect parent layer URL
				const parent = _.nth(newStack, -1);

				if(parent) {
					window.history.pushState(null, undefined, parent.location.pathname)
				}

				// set new stack
				this.setState({
					layerStack: newStack
				})

			},
			getInitialRouteOnLoad: () => {
				return initialLocation;
			}
		};

		this.layerStateCache = new Map();

		if(!isServer) {
			window.router = this;
		}

	}

	extendRouterContext(routerContext, layer) {

		if(!layer) {
			return routerContext;
		}

		const newContext = {
			...routerContext,
			routeInfo: layer.routeInfo, 
			layeredRouter: this.state,
			layerIndex: this.state.layerStack.indexOf(layer),
			match: layer.match, 
			location: layer.location
		}

		const lastContext = this.layerStateCache.get(layer.id);

		if(_.isEqual(newContext, lastContext)) {
			return lastContext;
		}

		this.layerStateCache.set(layer.id, newContext);

		return newContext;

	}

	renderLayers(start, end) {

		return this.state.layerStack.slice(start, end).map(layer => {

			// render every layer with it's own route context
			return <React.Fragment key={layer.id}>
				<__RouterContext.Consumer>
					{routerState => {
						const context = this.extendRouterContext(routerState, layer);
						return <__RouterContext.Provider value={context}>
							{layer.route.render?.call(this, layer, context) || null}
						</__RouterContext.Provider>
					}}
				</__RouterContext.Consumer>
			</React.Fragment>

		});

	}

	render() {

		return <__RouterContext.Consumer>
			{routerState => {
				const context = this.extendRouterContext(routerState, this.state.layerStack[0]);
				return <__RouterContext.Provider value={context}>
					{this.props.children}
				</__RouterContext.Provider>

			}}
		</__RouterContext.Consumer>

	}

	getLayerConfigForLocation = location => {
		
		let match = null;
		let route = null;
		
		for (const routeConfig of routes) {

			match = matchPath(location.pathname, { path: routeConfig.path, exact: routeConfig.exact ?? true });
			route = routeConfig;

			if(match) {
				// only match the first route we find
				break;
			}

		}

		return {
			id: _.uniqueId('layer-'),
			match,
			location,
			// make route immutable so we cache getters like isOverlay in their current state
			route: {...route},
			routeInfo: {
				isTemplates: match.path === paths.TEMPLATES,
				isCommunity: match.path === paths.COMMUNITY,
				isHomePage: match.path === paths.ROOT,
				isStaticPage: match.path === paths.STATIC_PAGES,
				isFonts: match.path === paths.FONTS,
				isPublicFolder: match.path === paths.PUBLIC_FOLDER,
				isUserFolder: match.path === paths.FOLDER || ( match.path === paths.ROOT && this.props.authenticated ),
			}
		}

	}

	addBaseRoute = (layerStack, nextLayer, nextLocation) => {

		// get base route URL
		let basePath;

		if(nextLayer.route.basePath) {

			if(typeof nextLayer.route.basePath === "function") {
				// passed as a callback fn
				basePath = nextLayer.route.basePath(nextLayer);
			} else {
				// passed as a string
				basePath = nextLayer.route.basePath
			}

		}

		layerStack.unshift(
			this.getLayerConfigForLocation({
				...nextLocation,
				pathname: basePath || '/',
				key: Math.random().toString(36).substr(2, 8)
			})
		)

	}

	getNextLayerStack = (currentLayerStack, nextLocation) => {

		let currentLayer = _.nth(currentLayerStack, -1);
		const parentLayer = _.nth(currentLayerStack, -2);
		const nextLayer = this.getLayerConfigForLocation(nextLocation);

		// console.log({
		// 	currentLayer,
		// 	parentLayer,
		// 	nextLayer
		// })
		
		let newLayerStack = [...currentLayerStack];

		if(
			!currentLayer 
			|| ( 
				// next route is an overlay
				nextLayer.route.isOverlay 
				// and that overlay is a different route
				&& currentLayer?.route.path !== nextLayer?.route.path
				// and we're not going back to the parent overlay's route
				&& parentLayer?.route.path !== nextLayer?.route.path
			)
		) {

			// When directly opening an overlay we need to make sure the main site
			// is rendered below it. Do this by adding a stack item for the site's root route
			if(!currentLayer && nextLayer.route.isOverlay) {
				this.addBaseRoute(newLayerStack, nextLayer, nextLocation);
			}

			// If no stack item exists yet, or if the new stack item is an overlay, create a new stack
			newLayerStack.push(nextLayer)
			
		} else {

			if(
				newLayerStack.length > 1
				&& _.nth(newLayerStack, -1).route.isOverlay
				&& (
					// navigated back to the parent layer route
					parentLayer?.route.path === nextLayer?.route.path
					// or routed to a different layer
					|| currentLayer?.route.path !== nextLayer?.route.path
				)
			) {

				// kill top layer from stack
				newLayerStack.pop();

				currentLayer = parentLayer;
			}

			// update current stack item
			newLayerStack = newLayerStack.map(layer => {

				if(layer === currentLayer) {
					// update the current stack item
					return {
						...nextLayer,
						// retain the ID
						id: layer.id
					}
				}

				// leave other stack items as-is
				return layer;

			})


			// ensure we have an underlay
			if(newLayerStack[0]?.route.isOverlay !== false) {
				this.addBaseRoute(newLayerStack, nextLayer, nextLocation);
			}

		}

		return newLayerStack

	}

	route = (nextLocation) => {

		const newLayerStack = this.getNextLayerStack(this.state.layerStack, nextLocation);

		this.setState({
			layerStack: newLayerStack
		})

	}

	shouldComponentUpdate(nextProps) {

		if (
			this.props.location !== nextProps.location
			|| this.props.authenticated !== nextProps.authenticated
		) {
			this.route(nextProps.location);
		}

		return true;
	}


}

export const routesByPath = _.keyBy(routes, 'path');
export const routesInUse = routes.map(route => _.pick(route, ['path', 'redirectPath', 'requireLogin']));

export default withRouter(connect(
	(state, ownProps) => ({
		authenticated: state.auth.authenticated,
		isMobile: state.homepageState.isMobile
	})
)(Router));