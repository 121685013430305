import React, { useMemo, useContext } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSortable } from '@dnd-kit/sortable';
import _ from 'lodash'
import { CSS } from '@dnd-kit/utilities';
import { DroppableContext } from "./droppable-context-wrapper"
import { NavLink } from 'react-router-dom'
import { actions } from "../actions";
import { MenuContext } from "@cargo/common/context-menu/context-menu-controller";
import FolderContextUI from "./folder-context-ui";
import FolderIcon from "@cargo/common/icons/folder.svg";
import { withRouter } from 'react-router-dom';

const Folder = ({ isDisabled, account, item, id, updateFolder, isDragging = false, history }) => {

    const {activeItem, setShouldCancelDrop, setShouldAnimate} = useContext(DroppableContext);

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ 
        id,
        disabled: isDisabled
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const folder = useMemo(() => item, [item])
    let isRootFolder = folder.id === account.root_folder_id;

    const handlePointerUp = e => {
    }

    const handlePointerEnter = () => {
        setShouldCancelDrop(true)
    }

    const handlePointerLeave = () => {
        setShouldCancelDrop(false)
    }

    let folderRoute = folder.slug === 'All' ? '/' : folder.slug;

    if( !folder.slug ){ return ( null ) }

    return (
        <>
            {folder && <MenuContext.Consumer>
                {(Menu) =>
                    <span
                        style={style}
                    >
                        <NavLink
                            draggable={false}
                            ref={setNodeRef}
                            to={'/' + folderRoute}
                            folder-id={folder.id}
                            onPointerUp={handlePointerUp}
                            onPointerEnter={handlePointerEnter}
                            onPointerLeave={handlePointerLeave}
                            activeClassName='selected'
                            exact={isRootFolder || folderRoute === 'community'}
                            style={{
                                pointerEvents: isDragging ? 'none' : '',
                                background: isDragging ? 'none' : ''
                            }}
                            className={`button-link`}
                            onClick={(e) => {

                                // Disable animations when navigating to new folder
                                setShouldAnimate(false);

                                // disable default link behavior
                                e.preventDefault();

                                // manually push with the restoreScroll option enabled
                                history.push('/' + folderRoute, {restoreScroll: true});

                            }}
                            onContextMenu={e => {
                                // don't do a context menu on the root folder
                                if (isRootFolder) return;
                                e.preventDefault();
                                // clear the user's selection
                                (window.getSelection ? window.getSelection() : document.selection).empty()

                                Menu.openMenu({
                                    innerUI: <FolderContextUI folder={folder} />,
                                    type: 'mouse',
                                    yFromButton: true,
                                    event: e,
                                })
                            }
                            }
                        >
                            <div className="folder-icon"><FolderIcon /></div>
                            <div className="folder-title">{folder.name}</div>
                        </NavLink>
                    </span>
                }
            </MenuContext.Consumer>}
        </>
    )

}

function mapReduxStateToProps(state, ownProps) {

    return {
        account: state.account,
    };

}


function mapDispatchToProps(dispatch) {
	
	return bindActionCreators({
		updateFolder: actions.updateFolder,
	}, dispatch);

}

export default withRouter(connect(
    mapReduxStateToProps, 
    mapDispatchToProps
)(Folder));